<template>
  <Layout>
    <iframe :name="label" :src="source" class="frame"></iframe>
  </Layout>
</template>

<script>

import { mapGetters } from "vuex"

export default {
  name: "CustomLink",
  computed: {
    ...mapGetters({
      getSettings: "config/getSettings"
    }),
    label() {
      const { index } = this.$route.params;
      return this.getSettings[`link_menu_${index}_label`];
    },
    source() {
      const { index } = this.$route.params;
      return this.getSettings[`link_menu_${index}_url`];
    }
  }
}

</script>

<style lang="scss" scoped>
.frame {
  height: 100vh;
  width: 100%;
  border: none;
}
</style>